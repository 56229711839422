<template>
  <div id="app">
    <div
      style="
        height: 60px;
        background-color: darkblue;
        color: white;
        text-align: left;
        line-height: 60px;
        font-size: 35px;
        font-weight: 500;
        padding-left: 20px;
      "
    >
      Machine Rate Should Cost Database
    </div>
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
/* 全局样式可以在这里添加 */
body {
  padding: 0;
  margin: 0;
}
</style>

// import './plugins/axios'
import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
// import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
// import {Plus, Minus} from '@element-plus/icons-vue'

import "./static/main.css"
// import "./static/scores.css"
// import zhCn from 'element-plus/dist/locale/zh-cn.mjs'

// import _axios from './plugins/axios'

const app = createApp(App);
// installAxios(app)
// app.config.globalProperties.$axios = _axios;
app.use(ElementPlus);
app.use(router);
app.mount("#app")
// app.component(Plus.name, Plus)
// app.component(Minus.name, Minus)
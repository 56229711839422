import { createRouter, createWebHashHistory } from "vue-router";

const router = createRouter({
  history: createWebHashHistory(), // 这样的话网址之中会带一个#，但是不会带来刷新错误
  routes: [
    {
      path: "/",
      name: "Home",
      component: () => import("../views/HomeView.vue"),
      meta: { title: "Machine Rate Break Down" },
    },
  ],
});

// 如何给每个页面动态切换标题
router.beforeEach((to, from, next) => {
  window.document.title = to.meta.title;
  next();
});

export default router;
